import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/builds/proscom/hse-design/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from 'docz';
import { Person } from '@hse-design/react';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "person"
    }}>{`Person`}</h1>
    <pre><code parentName="pre" {...{
        "className": "language-ts"
      }}>{`import { Person } from '@hse-design/react'
`}</code></pre>
    <h2 {...{
      "id": "описание"
    }}>{`Описание`}</h2>
    <p>{`Person – компонент для отображения аватарки пользователя или изображения человека. Используется в компоненте Blockquote.`}</p>
    <p>{`Все переданные атрибуты (например, `}<inlineCode parentName="p">{`src`}</inlineCode>{`, `}<inlineCode parentName="p">{`alt`}</inlineCode>{`) передаются элементу `}<inlineCode parentName="p">{`img`}</inlineCode>{`.`}</p>
    <Playground __position={0} __code={'<Person\n  src=\"https://upload.wikimedia.org/wikipedia/commons/7/7c/Profile_avatar_placeholder_large.png\"\n  alt=\"Person\"\n/>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Person,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Person src="https://upload.wikimedia.org/wikipedia/commons/7/7c/Profile_avatar_placeholder_large.png" alt="Person" mdxType="Person" />
    </Playground>
    <h2 {...{
      "id": "примеры"
    }}>{`Примеры`}</h2>
    <h3 {...{
      "id": "произвольный-размер"
    }}>{`Произвольный размер`}</h3>
    <p>{`У компонента один размер: `}<inlineCode parentName="p">{`104x104`}</inlineCode>{` пикселей. Вы можете задать произвольные размеры компонента с помощью тега `}<inlineCode parentName="p">{`style`}</inlineCode>{` или передав css-класс в проп `}<inlineCode parentName="p">{`className`}</inlineCode></p>
    <Playground __position={1} __code={'<Person\n  style={{ width: \'64px\', height: \'64px\' }}\n  src=\"https://upload.wikimedia.org/wikipedia/commons/7/7c/Profile_avatar_placeholder_large.png\"\n  alt=\"Person\"\n/>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Person,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Person style={{
        width: '64px',
        height: '64px'
      }} src="https://upload.wikimedia.org/wikipedia/commons/7/7c/Profile_avatar_placeholder_large.png" alt="Person" mdxType="Person" />
    </Playground>
    <h2 {...{
      "id": "пропы"
    }}>{`Пропы`}</h2>
    <Props of={Person} mdxType="Props" />
    <p>{`Все остальные пропы пробрасываются на элемент `}<inlineCode parentName="p">{`img`}</inlineCode></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      